<template>
  <div class="box-office-page">
    <div class="header">
      <span class="title">本年度票房</span>
      <div class="update-time">最后更新：{{ updateTime }}</div>
    </div>

    <div class="data-table">
      <div class="table-header">
        <div class="col">月份</div>
        <div class="col">票房</div>
        <div class="col">服务费</div>
        <div class="col">票房同比</div>
      </div>

      <div class="table-body">
        <div
          v-for="(item, index) in dataList"
          :key="index"
          class="table-row"
          :class="{ 'row-even': index % 2 === 1 }"
        >
          <div class="col month">{{ item.month }}</div>
          <div class="col amount">{{ item.boxOffice }}</div>
          <div class="col amount">{{ item.serviceCharge }}</div>
          <div class="col">
            <span
              class="percentage"
              :class="{
                'increase': item.onBoxOffice > 0,
                'decrease': item.onBoxOffice < 0
              }"
            >
              {{ (item.onBoxOffice * 100).toFixed(2) + '%' }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="description">
      <van-icon name="info-o" />
      <span>展示当前影院当年每个月票房统计数据</span>
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
import { Toast } from 'vant'

export default {
  name: 'YearBoxOffice',
  data() {
    return {
      cinemaId: '',
      updateTime: '2000-01-01',
      dataList: [],
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id || ''
    this.getYearData()
  },
  methods: {
    getYearData() {
      const userCode = this.$store.state.userInfo.mobile
      const query = {
        UserCode: userCode,
        CmdIndex: "101601",
        cmdArgs: [this.cinemaId]
      }
      zjApi.getPs(query).then((r) => {
        const { result: data, code, Message } = r
        if (code === 200) {
          this.updateTime = data.UpdateDate
          this.dataList = data.monthDatas.map((item) => ({
            month: `${item.DateMonth}月`,
            boxOffice: `${(item.BoxOfficeNoService/10000).toFixed(2)}万`,
            serviceCharge: `${(item.ServiceFee/10000).toFixed(2)}万`,
            onBoxOffice: Number(item.CompareBoxOfficeNoService) / 100,
          }))
        } else {
          Toast(Message)
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.box-office-page {
  min-height: 100vh;
  background: #f5f7fa;
  padding: 16px;
}

.header {
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  padding: 20px;
  border-radius: 12px;
  color: #fff;
  margin-bottom: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .title {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-bottom: 8px;
  }

  .update-time {
    font-size: 13px;
    opacity: 0.8;
  }
}

.data-table {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);

  .table-header {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background: #f8f9fa;
    padding: 16px 12px;
    font-weight: 500;
    color: #666;
    font-size: 14px;
  }

  .table-body {
    .table-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 16px 12px;
      transition: background-color 0.3s ease;

      &.row-even {
        background: #f8f9fa;
      }

      &:hover {
        background: #f0f7ff;
      }
    }
  }

  .col {
    text-align: center;
    font-size: 14px;
    color: #333;

    &.month {
      color: #666;
    }

    &.amount {
      color: #1989fa;
      font-weight: 500;
    }
  }

  .percentage {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 13px;

    &.increase {
      color: #52c41a;
      background: rgba(82, 196, 26, 0.1);
      &::before {
        content: "↑";
        margin-right: 2px;
      }
    }

    &.decrease {
      color: #f5222d;
      background: rgba(245, 34, 45, 0.1);
      &::before {
        content: "↓";
        margin-right: 2px;
      }
    }
  }
}

.description {
  margin-top: 16px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #999;
  font-size: 13px;

  .van-icon {
    font-size: 16px;
  }
}
</style>
